import React from "react";
import { Link } from "react-router-dom";

const PolicyBanner = () => {
    return (
        <div className="mil-inner-banner">
            <div className="mil-banner-content mil-up">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-4 mil-dark mil-scale" data-value-1="6" data-value-2="1.4"></div>
                </div>
                <div className="container">
                    <ul className="mil-breadcrumbs mil-mb-60">
                        <li><Link to="/">Domovská stránka</Link></li>
                        <li><Link to="/gdpr">Zásady ochrany osobních údajů</Link></li>
                    </ul>
                    <h1 className="mil-mb-60">Zásady <span className="mil-thin">ochrany </span> <br /> <span className="mil-thin">osobních </span>údajů</h1>
                    <p className="mil-mb-10 mil-dark mil-center">
                        Společnost <b>Lukáš Pražák</b>, se sídlem <b>Černěves 29, 413 01, Černěves</b>, IČ: <b>87729580</b> (dále jen "společnost"), 
                        jako správce osobních údajů, tímto poskytuje informace o zpracování osobních údajů na webové stránce <b>www.negarsonka.cz</b> v souladu s nařízením Evropského parlamentu a Rady (EU) 2016/679 (GDPR).
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PolicyBanner;