import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input/input";
import { Link } from "react-router-dom";

const ContactForm = () => {
    const form = useRef();
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [apartmentSize, setApartmentSize] = useState('');
    const [message, setMessage] = useState('');
    const [variant, setVariant] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const [loading, setLoading] = useState(false);

    const variants = ['BASIC', 'CUSTOM']

    const sendEmail = (e) => {
        e.preventDefault();

        if (!recaptchaVerified) {
            alert("Ověřte prosím, zda jste člověk!");
            return;
        }

        setLoading(true);

        emailjs
            .sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_API_KEY)
            .then(
                (result) => {
                    console.log('SUCCESS!', result.text);
                    setSubmitted(true);
                    setName('');
                    setEmail('');
                    setMessage('');
                    setLoading(false);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setLoading(false);
                },
            );
    };

    const onRecaptchaChange = (value) => {
        setRecaptchaVerified(!!value);
    };

    const handlePhoneFocus = () => {
        if (!phone) {
            setPhone('+420');
        }
    };

    const VariantSelector = () => {
        return (
            <div className="col-lg-6 mil-up">
                <select
                    name="variant"
                    value={variant}
                    onChange={(e) => setVariant(e.target.value)}
                    required
                >
                    <option value="" disabled>
                        Verze vestavby
                    </option>
                    {variants.map((x) => (
                        <option key={x} value={x}>
                            {x}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <div id="contact-page" className="container mil-p-0-90">
            <section id="contact">
                <div className="mi-invert-fix">
                    <div className="mil-animation-frame">
                        <div className="mil-animation mil-position-4 mil-dark mil-scale" data-value-1="6" data-value-2="1.4"></div>
                    </div>
                    <div className="container mil-p-90-90">
                        <div className="mil-mb-60">
                            <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
                                <h2 className="mil-h1 mil-up mil-center">NE<span className="mil-thin">BOJTE SE PUSTIT SI NÁS DOMŮ</span></h2>
                            </div>
                        </div>
                        <div className="row justify-content-around mil-p-90-90">
                            <div className="mil-about-quote col-lg-5 mil-mb-20">
                                <div className="mil-avatar mil-up">
                                    <img src={`${process.env.PUBLIC_URL}/img/faces/f2.jpg`} alt="Lukáš Mathers" />
                                </div>
                                <div className="mil-quote">
                                    <h6 className="mil-quote mil-up">Lukáš Mathers</h6>
                                    <h6 className="mil-quote mil-up">mathers@negarsonka.cz</h6>
                                </div>

                            </div>
                            <div className="mil-about-quote col-lg-5 mil-mb-20">
                                <div className="mil-avatar mil-up">
                                    <img src={`${process.env.PUBLIC_URL}/img/faces/1.jpg`} alt="Name Surname" />
                                </div>
                                <div className="mil-quote">
                                    <h6 className="mil-quote mil-up">Lukáš Pražák</h6>
                                    <h6 className="mil-quote mil-up">prazak@negarsonka.cz</h6>
                                </div>
                            </div>
                        </div>
                        <h3 className="mil-center mil-up mil-mb-90">Kontaktujte nás <span className="mil-thin">pro nezávaznou cenovou nabídku</span><br /></h3>
                        {submitted ? (
                            <div className="mil-center mil-up mil-mb-90">
                                <h3>Děkujeme za poptávku! Brzy se Vám ozveme.</h3>
                            </div>
                        ) : (
                            <form ref={form} className="row align-items-center" onSubmit={sendEmail}>
                                <input type="hidden" name="from_email" value="info@negarsonka.cz" />
                                <div className="col-lg-6 mil-up">
                                    <input
                                        type="text"
                                        placeholder="Vaše jméno"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 mil-up">
                                    <input
                                        type="text"
                                        placeholder="Vaše příjmení"
                                        name="surname"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 mil-up">
                                    <input
                                        type="email"
                                        placeholder="Váš Email"
                                        name="  "
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-lg-6 mil-up">
                                    <PhoneInput
                                        placeholder="Váš Telefon"
                                        name="phone_number"
                                        value={phone}
                                        onFocus={handlePhoneFocus}
                                        onChange={(value) => setPhone(value)}
                                    />
                                </div>
                                <div className="col-lg-6 mil-up">
                                    <input
                                        type="number"
                                        placeholder="Velikost v m²"
                                        name="apartment_size"
                                        value={apartmentSize}
                                        onChange={(e) => setApartmentSize(e.target.value)}
                                        min={10}
                                        required
                                    />
                                </div>
                                <VariantSelector />
                                <div className="col-lg-12 mil-up">
                                    <textarea
                                        placeholder="Podělte se o Vaší představu"
                                        name="message"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-12 mil-up">
                                    <ReCAPTCHA
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={onRecaptchaChange}
                                    />
                                </div>
                                <div className="col-lg-8">
                                    <p className="mil-up mil-mb-30">
                                        <span className="mil-accent">*</span> Odesláním tohoto formuláře souhlasíte s našimi
                                        <Link to="/gdpr" className="mil-accent"> zásadami GDPR </Link> a zpracováním Vašich osobních údajů.
                                    </p>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mil-adaptive-right mil-up mil-mb-30">
                                        <button type="submit" className="mil-button mil-arrow-place" disabled={loading}>
                                            {loading ? "Odesílání..." : "Odeslat"}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};


export default ContactForm;