import React from "react";

const BackToTopButton = () => {
    return (
        <div className="mil-frame-bottom">
            <div className="mil-current-page">
            </div>
            <div className="mil-back-to-top">
                <a href="#top" className="mil-link mil-dark mil-arrow-place">
                    <span>Back to top</span>
                </a>
            </div>
        </div>
    );
};

export default BackToTopButton;