import React, { useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import $ from 'jquery';
import Swup from 'swup';
import Swiper from 'swiper';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

const LoadMainJS = () => {
  useEffect(() => {
    // Assign jQuery to window object
    window.$ = window.jQuery = $;

    // Initialize Swup
    window.swup = new Swup({
      containers: ['#swupMain', '#swupMenu'],
      animateHistoryBrowsing: true,
      linkSelector: 'a:not([data-no-swup])',
      animationSelector: '[class="mil-main-transition"]'
    });

    // Assign Swiper to window object
    window.Swiper = Swiper;

    // Register GSAP plugins
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    // Assign GSAP and plugins to window object
    window.gsap = gsap;
    window.ScrollTrigger = ScrollTrigger;
    window.ScrollToPlugin = ScrollToPlugin;
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        <script src={`${process.env.PUBLIC_URL}/scripts/plugins/fancybox.min.js`} type="text/javascript" />
        <script src={`${process.env.PUBLIC_URL}/scripts/plugins/smooth-scroll.js`} type="text/javascript" />
        <script src={`${process.env.PUBLIC_URL}/scripts/plugins/tilt.js`} type="text/javascript" />
        <script src={`${process.env.PUBLIC_URL}/scripts/main.js`} type="text/javascript" />
      </Helmet>
    </HelmetProvider>
  );
};

export default LoadMainJS;