import React from "react";
import { Link } from 'react-router-dom';


const PrivacyContent = () => {
    return (
        <section id="blog">
            <div className="container mil-p-120-90">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <h5 className="mil-up mil-mb-30">1. Jaké údaje zpracováváme?</h5>
                        <p className="mil-up mil-mb-20">
                            Na naší webové stránce zpracováváme následující osobní údaje, které nám poskytnete prostřednictvím kontaktního formuláře:
                        </p>
                        <div className="mil-up mil-mb-20">
                            <p><b>Jméno a příjmení</b></p>
                            <p><b>E-mailová adresa</b></p>
                            <p><b>Telefonní číslo</b></p>
                            <p><b>Velikost bytu v m²</b></p>
                            <p><b>Typ vestavby</b></p>
                            <p><b>Vaše zpráva</b></p>
                        </div>
                        <p className="mil-up mil-mb-60">
                            Tyto údaje jsou zpracovávány výhradně za účelem zpětného kontaktování a vyřízení vaší poptávky či dotazu.
                        </p>

                        <h5 className="mil-up mil-mb-30">2. Účel a právní základ zpracování</h5>
                        <p className="mil-up mil-mb-20">
                            Osobní údaje, které nám poskytnete prostřednictvím kontaktního formuláře, zpracováváme na základě vašeho souhlasu.
                            Tyto údaje používáme pouze k tomu, abychom vás mohli kontaktovat ohledně vaší poptávky či dotazu
                        </p>
                        <span className="mil-dark mil-text-xl">
                            Údaje, které zpracováváme:
                        </span>
                        <div className="mil-up mil-mb-60">
                            <p><b>Jméno a příjmení:</b> pro identifikaci a osobní oslovení.</p>
                            <p><b>E-mailová adresa:</b> pro zasílání odpovědí na vaše dotazy.</p>
                            <p><b>Telefonní číslo:</b> pro případné rychlejší vyřízení vašeho dotazu či poptávky.</p>
                            <p><b>Velikost bytu:</b> pro přizpůsobení našich služeb vaší poptávce.</p>
                            <p><b>Typ vestavby:</b> přizpůsobení našich služeb vaší poptávce.</p>
                            <p><b>Zpráva:</b> pro řešení vašich konkrétních dotazů či požadavků</p>
                        </div>

                        <h5 className="mil-up mil-mb-30">3. Doba uchování údajů</h5>
                        <p className="mil-up mil-mb-60">
                            Vaše osobní údaje uchováváme po dobu nezbytnou pro vyřízení vaší poptávky či dotazu. Jakmile již nebudou tyto údaje potřebné, budou zlikvidovány v souladu s platnými právními
                            předpisy.
                        </p>

                        <h5 className="mil-up mil-mb-30">4. Cookies</h5>
                        <p className="mil-up mil-mb-30">
                            Naše webová stránka používá cookies k zajištění správné funkčnosti webu a ke zlepšení uživatelského komfortu.
                            Cookies jsou malé textové soubory, které váš prohlížeč ukládá na váš pevný disk.
                        </p>
                        <span className="mil-dark mil-text-xl mil-mb-30">
                            Používáme následující typy cookies:
                        </span>
                        <div className="mil-up mil-mb-30">
                            <p><b>Nezbytné cookies:</b> zajišťují základní funkčnost webové stránky.</p>
                            <p><b>Analytické cookies:</b> nám umožňují analyzovat návštěvnost a chování uživatelů na webu, abychom mohli zlepšovat jeho funkčnost.</p>
                            <p><b>Marketingové cookies:</b> jsou využívány pro zobrazování personalizované reklamy.</p>
                        </div>
                        <p className="mil-up mil-mb-60">Používání cookies můžete kdykoliv omezit nebo zakázat v nastavení vašeho prohlížeče. Upozorňujeme však, že v takovém případě nemusí být některé části webu plně funkční.</p>

                        <h5 className="mil-up mil-mb-30">5. Google reCAPTCHA</h5>
                        <p className="mil-up mil-mb-30">
                            Na naší webové stránce používáme službu Google reCAPTCHA k ochraně formulářů proti zneužití a spamu. Služba zajišťuje, že formuláře vyplňují skuteční lidé, nikoli automatizované programy (tzv. boti).
                        </p>
                        <span className="mil-dark mil-text-xl mil-mb-30">
                            Google reCAPTCHA může shromažďovat následující údaje:
                        </span>
                        <div className="mil-up mil-mb-30">
                            <p><b>IP adresu</b></p>
                            <p><b>Informace o vašem prohlížeči a zařízení</b></p>
                            <p><b>Pohyby myši a další technické údaje</b></p>
                        </div>
                        <p className="mil-up mil-mb-60">
                            Tyto údaje jsou předávány společnosti Google a zpracovávány v souladu s jejími zásadami ochrany osobních údajů, které naleznete na adrese:
                            <a href="https://policies.google.com/privacy" className="mil-accent"> https://policies.google.com/privacy</a>.
                        </p>

                        <h5 className="mil-up mil-mb-30">6. Příjemci osobních údajů</h5>
                        <p className="mil-up mil-mb-60">
                            Vaše osobní údaje nepředáváme žádným třetím stranám s výjimkou případů, kdy je to nezbytné pro splnění právních povinností,
                            nebo kdy je to v souladu s našimi oprávněnými zájmy (například poskytovatelům IT služeb a správy webu).
                        </p>


                        <h5 className="mil-up mil-mb-30">7. Vaše práva</h5>
                        <span className="mil-dark mil-text-xl mil-mb-30">
                            V souvislosti se zpracováním osobních údajů máte následující práva:
                        </span>
                        <div className="mil-up mil-mb-30">
                            <p><b>Právo na přístup k vašim osobním údajům.</b></p>
                            <p><b>Právo na opravu nepřesných údajů.</b></p>
                            <p><b>Právo na výmaz osobních údajů ("právo být zapomenut").</b></p>
                            <p><b>Právo na omezení zpracování.</b></p>
                            <p><b>Právo na přenositelnost údajů.</b></p>
                            <p><b>Právo odvolat svůj souhlas se zpracováním osobních údajů.</b></p>
                        </div>
                        <p className="mil-up mil-mb-60">
                            Pro uplatnění svých práv nás můžete kontaktovat na e-mailové adrese <b className="mil-accent"><a href="mailto:info@negarsonka.cz">info@negarsonka.cz</a></b> nebo na adrese naší společnosti <b>Černěves 29, 413 01, Černěves</b>.
                        </p>


                        <h5 className="mil-up mil-mb-30">8. Závěrečná ustanovení</h5>
                        <p className="mil-up mil-mb-30">
                            Zásady ochrany osobních údajů mohou být čas od času aktualizovány. Aktuální verze je vždy dostupná na naší webové stránce
                            <b className="mil-accent"><Link to="/gdpr"> negarsonka.cz/gdpr </Link></b>.  
                        </p>
                        <p className="mil-up mil-mb-60">
                            Datum poslední aktualizace: <b>13.9.2024</b>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PrivacyContent;