import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Products = () => {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 300,
        fade: true,
        cssEase: 'linear',
    };

    const goToFirstSlide = () => {
        sliderRef.current.slickGoTo(0);
    };

    const goToSecondSlide = () => {
        sliderRef.current.slickGoTo(1);
    };

    const BasicControlBtn = () => {
        return (
            <div className="mil-adaptive-left mil-up" onClick={goToFirstSlide} >
                <div className="mil-link mil-dark mil-icon-left" style={{justifyContent: 'start'}}>
                <span>Basic</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
                    </svg>
                </div>
            </div>
        );
    };

    const CustomControlBtn = () => {
        return (
            <div className="mil-adaptive-right mil-up" onClick={goToSecondSlide} >
                <div className="mil-link mil-dark">
                    <span>Custom</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M 14 5.3417969 C 13.744125 5.3417969 13.487969 5.4412187 13.292969 5.6367188 L 13.207031 5.7226562 C 12.816031 6.1136563 12.816031 6.7467188 13.207031 7.1367188 L 17.070312 11 L 4 11 C 3.448 11 3 11.448 3 12 C 3 12.552 3.448 13 4 13 L 17.070312 13 L 13.207031 16.863281 C 12.816031 17.254281 12.816031 17.887344 13.207031 18.277344 L 13.292969 18.363281 C 13.683969 18.754281 14.317031 18.754281 14.707031 18.363281 L 20.363281 12.707031 C 20.754281 12.316031 20.754281 11.682969 20.363281 11.292969 L 14.707031 5.6367188 C 14.511531 5.4412187 14.255875 5.3417969 14 5.3417969 z"></path>
                    </svg>
                </div>
            </div>
        );
    };

    return (
        <section id="garsonky">
            <div className="mi-invert-fix">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-1 mil-scale" data-value-1="2.4" data-value-2="1.4" style={{ top: '300px', right: '-100px' }}></div>
                    <div className="mil-animation mil-position-2 mil-scale" data-value-1="2" data-value-2="1" style={{ left: '150px' }}></div>
                </div>
                <div className="container mil-p-120-0">
                    <div className="mil-mb-60">
                        <div className="mil-complex-text justify-content-center mil-up mil-mb-15">
                            <h2 className="mil-h1 mil-up mil-center"><span className="mil-thin">NAŠE</span> NE<span className="mil-thin">GARSONKY</span></h2>
                        </div>
                    </div>
                    <Slider {...settings} ref={sliderRef} className="mil-mb-60">
                        <BasicModel />
                        <CustomModel />
                    </Slider>
                    <div className="row align-items-center mil-mb-30 d-none d-lg-flex">
                        <div className="col-lg-6 mil-mb-30">
                            <BasicControlBtn />
                        </div>
                        <div className="col-lg-6 mil-mb-30">
                            <div className="mil-adaptive-right mil-up" >
                                <CustomControlBtn />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

const BasicModel = () => {
    return (
        <>
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-xl-5">
                    <div className="mil-mb-20">
                        <h3 className="mil-up mil-mb-60">BASIC MODEL</h3>
                        <p className="mil-up mil-mb-30"> Nabízíme několik řešení vestaveb. Každé z nich je optimalizováno pro jiné požadavky a
                            představy. A každé z nich vytvoří z vašeho bytu prostor pro vaši osobitost.
                        </p>
                        <p className="mil-up mil-mb-60">Together, our creative team is committed to delivering impactful work that exceeds expectations.</p>
                        <h4 className="mil-up"><span className="mil-thin">We</span> delivering <br /><span className="mil-thin">exceptional</span> results.</h4>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img src={`${process.env.PUBLIC_URL}/img/floorplans/fp3.jpeg`} className="carousel-image" alt="Image 3" />
                </div>
            </div>
        </>
    );
};

const CustomModel = () => {
    return (
        <>
            <div className="row justify-content-between align-items-center">
                <div className="col-lg-5 col-xl-5">
                    <div className="mil-mb-20">
                        <h3 className="mil-up mil-mb-60">CUSTOM</h3>
                        <p className="mil-up mil-mb-30"> Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Proin in tellus sit amet nibh dignissim sagittis. Nam quis nulla. Duis risus.
                            Nullam rhoncus aliquam metus. Mauris dictum facilisis augue. Maecenas lorem. Etiam bibendum elit eget erat.
                        </p>
                        <p className="mil-up mil-mb-60">
                            Duis condimentum augue id magna semper rutrum. In convallis. Nunc dapibus tortor vel mi dapibus sollicitudin. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                        </p>
                        <h4 className="mil-up"><span className="mil-thin">We</span> delivering <br /><span className="mil-thin">exceptional</span> results.</h4>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img src={`${process.env.PUBLIC_URL}/img/floorplans/fp1.png`} className="carousel-image" alt="Image 1" />
                </div>
            </div>
        </>
    );
};

export default Products;