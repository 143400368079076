import React from "react";

const TopLogoFrame = () => {
    const logoPath = `${process.env.PUBLIC_URL}/img/logos/small/3.png`;

    return (
        <div className="mil-frame-top">
            <div className="mil-logo">
                <img src={logoPath} alt="Logo" className="mil-logo-image" />
            </div>
        </div>
    );
};

export default TopLogoFrame;    