import React from "react";

const Realization = () => {
    return (
        <section className="mil-soft-bg">
            <div className="mi-invert-fix">
                <div className="container mil-p-120-60">
                    <h2 className="mil-center mil-up mil-mb-60"><span className="mil-thin">VÁŠ</span> BYT<span className="mil-thin"> UŽ VÁS </span> NE<span className="mil-thin">MUSÍ </span>OMEZOVAT</h2>
                    <div className="mil-revi-pagination mil-up mil-mb-10"></div>
                    <div className="row">

                        <div className="row justify-content-between mil-p-90-90">
                            <div className="col-lg-5">
                                <h3 className="mil-up mil-mb-60" style={{
                                    translate: 'none',
                                    rotate: 'none',
                                    scale: 'none',
                                    transform: 'translate(0px, 0px)',
                                    opacity: '1'
                                }}>
                                    Jak proběhne realizace vaší nové Negarsonky?</h3>
                            </div>
                            <div className="col-lg-6">
                                <p className="mil-up mil-mb-20">
                                    Nejprve se s vámi osobně setkáme,
                                    abychom společně probrali vaše představy. Zjistíme, co vás na svém bydlení baví, a co
                                    byste naopak chtěli změnit.
                                </p>
                                <p className="mil-up mil-mb-20">
                                    Na základě tohoto setkání připravíme indikativní nabídku, která bude zahrnovat 3D model
                                    vašeho nového prostoru a předběžnou cenovou kalkulaci.
                                </p>
                                <p className="mil-up mil-mb-20">
                                    Jakmile se na představě shodneme, připravíme detailní položkovou cenovou nabídku,
                                    která se stane součástí návrhu smlouvy.
                                </p>
                                <p className="mil-up mil-mb-30">
                                    Po uhrazení zálohy se pustíme do stavby. Po celou dobu budete mít přehled o průběhu
                                    realizace. A samozřejmě vám dáme dostatečný prostor pro případné připomínky a přání,
                                    abychom mohli výsledek přizpůsobit přesně podle vašich potřeb. Váš komfort a
                                    spokojenost jsou pro nás na prvním místě.
                                </p>
                                <h4 className="mil-up mil-center">Jdete do toho <br /> <span className="mil-thin">s námi?</span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Realization;
