import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
    return (
        <div className="mil-content">
            <div id="swupMain" className="mil-main-transition">
                <div className="mil-404-banner mil-dark-bg">
                    <div className="mil-animation-frame">
                        <div className="mil-animation mil-position-4 mil-scale" data-value-1="9" data-value-2="1.4" style={{right: '40%'}}></div>
                    </div>
                    <div className="mi-invert-fix mil-up">
                        <div className="container">
                            <div className="mil-404-frame">
                                <div className="mil-scale-frame">
                                    <h1 className="mil-404" data-text="404">404</h1>
                                </div>
                                <h4 className="mil-404-text mil-dark mil-mb-60">Ups! Něco se pokazilo :(</h4>
                                <Link to="/" className="mil-button mil-arrow-place">
                                    <span> Zpět na domovskou stránku</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;