import React from "react";
import PolicyBanner from "./components/contentPages/PrivacyPolicy/PolicyBanner";
import PrivacyContent from "./components/contentPages/PrivacyPolicy/PrivacyContent";
import Footer from './components/Footer.js';


const PrivacyPolicyPage = () => {
    return (
        <>
            <PolicyBanner />
            <PrivacyContent />
            <Footer />
        </>
    );
};

export default PrivacyPolicyPage;