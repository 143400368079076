// import React from "react";
// import { Link } from 'react-router-dom';

// const Footer = () => {
//     const logoPath = `${process.env.PUBLIC_URL}/img/logos/small/3.png`;

//     return (
//         <footer className="mil-dark-bg">
//             <div className="mi-invert-fix">
//                 <div className="container mil-p-120-60">
//                     <div className="row justify-content-space-between">
//                         <div className="col-lg-6 mil-mb-60">
//                             <div className="mil-logo mil-up mil-mb-30">
//                                 <img src={logoPath} alt="Logo" className="mil-logo-image" />
//                             </div>
//                         </div>

//                         <div className="col-lg-6">
//                             <div className="row justify-content-start">
//                                 <div className="col-lg-6">
//                                     <ul className="mil-menu-list mil-up mil-mt-60">
//                                         <li>
//                                             <Link to="/" className="mil-light-soft">Úvodní stránka</Link>
//                                         </li>
//                                         <li>
//                                             <Link to="/gdpr" className="mil-light-soft">Zásady ochrany osobních údajů</Link>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="row justify-content-around flex-sm-row-reverse">
//                         <div className="col-md-10 col-lg-6 mil-mb-30">
//                             <h6 className="mil-muted mil-up">Česká republika</h6>
//                             <p className="mil-light-soft mil-up">Černěves 29, 413 01, Černěves <span className="mil-no-wrap"></span></p>
//                         </div>
//                         <div className="col-md-4 col-lg-6 mil-mb-60">
//                             <div className="mil-vert-between">
//                                 <div className="mil-mb-30">
//                                     <ul className="mil-social-icons mil-up">
//                                         <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-instagram"></i></a></li>
//                                         <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-facebook"></i></a></li>
//                                         <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-youtube"></i></a></li>
//                                         <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-twitter"></i></a></li>
//                                     </ul>
//                                 </div>
//                                 <p className="mil-light-soft mil-up">© Copyright NEGARSONKA 2024 - All Rights Reserved.</p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </footer>
//     );
// };

// export default Footer;

import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    const logoPath = `${process.env.PUBLIC_URL}/img/logos/small/3.png`;

    return (
        <footer className="mil-dark-bg">
            <div className="mi-invert-fix">
                <div className="container mil-p-120-60" style={{margin: 'auto', justifyContent: 'center'}}>
                    <div className="row justify-content-between mil-mb-60">
                        <div className="col-lg-6 col-md-12 mil-mb-60">
                            <div className="mil-logo mil-up mil-mb-30">
                                <img src={logoPath} alt="Logo" className="mil-logo-image" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="row justify-content-start">
                                <div className="col-lg-6 col-md-12">
                                    <ul className="mil-menu-list mil-up mil-mt-60">
                                        <li>
                                            <Link to="/" className="mil-light-soft">Úvodní stránka</Link>
                                        </li>
                                        <li>
                                            <Link to="/gdpr" className="mil-light-soft">Zásady ochrany osobních údajů</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-around flex-md-row">
                        <div className="col-md-12 col-lg-6 mil-mb-60">
                            <h6 className="mil-muted mil-up">Česká republika</h6>
                            <p className="mil-light-soft mil-up">Černěves 29, 413 01, Černěves <span className="mil-no-wrap"></span></p>
                        </div>
                        <div className="col-md-12 col-lg-6 mil-mb-60">
                            <div className="mil-vert-between">
                                <div className="mil-mb-30">
                                    <ul className="mil-social-icons mil-up">
                                        <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-instagram"></i></a></li>
                                        <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-facebook"></i></a></li>
                                        <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-youtube"></i></a></li>
                                        <li><a href="" target="_blank" className="social-icon"> <i className="fab fa-twitter"></i></a></li>
                                    </ul>
                                </div>
                                <p className="mil-light-soft mil-up">© Copyright NEGARSONKA 2024 - All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;