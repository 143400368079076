import React from 'react';

const ProgressTrack = () => {
    return (
        <div className="mil-progress-track">
            <div className="mil-progress"></div>
        </div>
    );
};

export default ProgressTrack;
