import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoadMainJS from './LoadMainJS.js';
import './assets/css/plugins/bootstrap-grid.css';
import 'font-awesome/css/font-awesome.min.css';
import 'swiper/swiper-bundle.css';
import 'fancybox/dist/css/jquery.fancybox.css';

import MainPage from './MainPage.js';
import ProgressTrack from './components/ProgressTrack.js';
import TopLogoFrame from './components/TopLogoFrame.js';
import BackToTopButton from './components/BackToTopButton.js';
import Footer from './components/Footer.js';
import PolygonAnimationsHidenElements from './components/PolygonAnimationsHidenElements.js';
import PrivacyPolicyPage from './PrivacyPolicePage.js';
import ErrorPage from './ErrorPage.js';
import Cursor from './components/Cursor.js';

function App() {
  return (
    <Router>
      <div className="App">
        <LoadMainJS />
        <div>
          <div className="mil-wrapper" id="top">
            <ProgressTrack />
            <Cursor />
            <div id="swupMenu"> </div>
            <div className="mil-curtain"></div>
            <div className="mil-frame">
              <TopLogoFrame />
              <BackToTopButton />
            </div>
            <div className="mil-content">
              <div id="swupMain" className="mil-main-transition">
                <Routes>
                  <Route exact path="/" Component={MainPage} />
                  <Route exact path="gdpr" Component={PrivacyPolicyPage} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
                <PolygonAnimationsHidenElements />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
