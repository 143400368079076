import React from 'react';

const Story = () => {
    return (
        <section id="about" className='mil-dark-bg'>
            <div className="mi-invert-fix">
                <div className="mil-animation-frame">
                    <div className="mil-animation mil-position-1 mil-scale" data-value-1="7" data-value-2="1.6"></div>
                    <div className="mil-animation mil-position-2 mil-scale" data-value-1="4" data-value-2="1"></div>
                    <div className="mil-animation mil-position-3 mil-scale" data-value-1="1.2" data-value-2=".1"></div>
                </div>
                <div className="container mil-p-120-30">
                    <div className="mil-mb-60">
                        <div className="mil-complex-text justify-content-center mil-muted mil-mb-15">
                            <h2 className="mil-h1 mil-muted mil-center"><span className="mil-thin">PROČ SE O NAŠE KNOW-HOW</span> NE<span className="mil-thin">PODĚLIT?</span></h2>
                        </div>
                    </div>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6 col-xl-5">
                            <div className="mil-mb-90">
                                <h3 className="mil-muted mil-mb-60">PŘÍBĚH <span className="mil-thin">LUKÁŠE</span></h3>
                                <p className="mil-muted mil-mb-30">Tohle je Lukáš, jeden ze zakladatelů Negarsonky. Když se svých XX letech sám přestěhoval
                                    do garsonky, zjistil, že malý prostor představuje velkou výzvu. Místo toho, aby se spokojil s
                                    nevyužitým potenciálem svého bydlení, rozhodl se z něj vytěžit maximum.
                                </p>

                                <p className="mil-muted mil-mb-30">
                                    Svépomocí přetvořil svou garsonku na prostor, který Lukášovi rázem dodal nové možnosti. A pocit pár
                                    metrů čtverečních navíc. A právě tehdy mu bleskla hlavou myšlenka: proč se o nabyté
                                    know-how z optimalizace malého bytu nepodělit s ostatními?
                                </p>

                                <h6 className="mil-muted mil-mb-60">
                                    Tak vznikla první Negarsonka. <span className="mil-thin">A s ní i naše mise - vytvářet lidem prostor pro lepší život.</span>
                                </h6>
                                <div className="mil-about-quote">
                                    <div className="mil-avatar mil-muted">
                                        <img src={`${process.env.PUBLIC_URL}/img/faces/f2.jpg`} alt="Lukáš Mathers" />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5">
                            <div className="mil-about-photo mil-mb-90">
                                <div className="mil-lines-place"></div>
                                <div className="mil-muted mil-img-frame" style={{ paddingBottom: '160%' }}>
                                    <img src={`${process.env.PUBLIC_URL}/img/photo/FullSizeRender.jpg`} alt="img" className="mil-scale" data-value-1="1" data-value-2="1.2" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Story;
